<script setup lang="ts">
import { ref, defineProps, computed, onMounted, nextTick, watch } from 'vue';
import { useLocationsStore } from '~/stores/LocationsStore';
import { useCarsStore } from '~/stores/CarsStore';
import { TIME_DATA } from '../layouts/timeData';
import { useNuxtApp, useRouter, useRoute } from '#app';

interface Point {
  id: number;
  name: string;
  slug?: string;
  type?: string;
}

const props = defineProps({
  type: { type: String, default: 'desktop' },
});

const nuxtApp = useNuxtApp();
const router = useRouter();
const route = useRoute();
const locationsStore = useLocationsStore();
const cars = useCarsStore();

const active = ref<Point | null>(null);
const activeOff = ref<Point | null>({ name: 'Там же' });
const isFocusedPickUp = ref(false);
const isFocusedDropOff = ref(false);
const timepicker1 = ref(false);
const timepicker2 = ref(false);
const pickUp = ref('');
const dropOff = ref('');
const calendar = ref<any>(null);
const calendarMob = ref<any>(null);
const promocode = ref<string>(route.query['promocode'] as string || '');

const getDefaultStartDate = () => {
  if (locationsStore.item?.searchParams['from-date']) {
    return nuxtApp.$moment(locationsStore.item.searchParams['from-date'], 'DD.MM.YYYY').toDate();
  } else if (route.query['from-date']) {
    return nuxtApp.$moment(route.query['from-date'], 'DD.MM.YYYY').toDate();
  } else {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date;
  }
};

const getDefaultEndDate = () => {
  if (route.query['to-date']) {
    return nuxtApp.$moment(route.query['to-date'], 'DD.MM.YYYY').toDate();
  } else if (locationsStore.item?.searchParams['to-date']) {
    return nuxtApp.$moment(locationsStore.item.searchParams['to-date'], 'DD.MM.YYYY').toDate();
  } else {
    const date = new Date();
    date.setDate(date.getDate() + 14);
    return date;
  }
};

const dateStart = ref<Date>(getDefaultStartDate());
const dateEnd = ref<Date>(getDefaultEndDate());

const isShowCalendar = ref(false);
const isShowCalendarMobile = ref(false);

const isPromocode = ref(!!route.query.promocode);

const fromTime = ref<string>((route.query['from-time'] as string) || '12:00');
const toTime = ref<string>((route.query['to-time'] as string) || '12:00');

const setFromTime = (time: string) => {
  fromTime.value = time;
  timepicker1.value = false;
};

const setToTime = (time: string) => {
  toTime.value = time;
  timepicker2.value = false;
};

const showTime = (pickerNumber: number) => {
  timepicker1.value = pickerNumber === 1;
  timepicker2.value = pickerNumber === 2;
};

const setPoint = async (point: Point) => {
  active.value = point;
  isFocusedPickUp.value = false;
  pickUp.value = '';
  await locationsStore.getDropOffList(active.value.id);
  activeOff.value = locationsStore.dropOffList.find(p => p.id === active.value?.id) || { name: 'Там же' };
};

const setPointDropOff = (point: Point) => {
  activeOff.value = point;
  isFocusedDropOff.value = false;
  dropOff.value = '';
};

const setActivePickupPoint = (id: number) => {
  return locationsStore.pickUpList.find(point => point.id === id) || locationsStore.pickUpList[0];
};

const setActiveDropOffPoint = (id: number) => {
  return locationsStore.dropOffList.find(point => point.id === id);
};

const setDefaultParams = async () => {
  const fromId = Number(locationsStore.item?.searchParams['from-id'] || route.query['from-id']);
  const toId = Number(route.query['to-id'] || locationsStore.item?.searchParams['to-id']);

  active.value = setActivePickupPoint(fromId);
  await locationsStore.getDropOffList(active.value.id);

  if (locationsStore.dropOffList) {
    activeOff.value = setActiveDropOffPoint(toId) || setActiveDropOffPoint(active.value.id) || { name: 'Там же' };
  }
};

const toggleDatepicker = () => {
  isShowCalendar.value = !isShowCalendar.value;
};

const toggleDatepickerMobile = () => {
  isShowCalendarMobile.value = !isShowCalendarMobile.value;
};

const toggleIsPromocode = () => {
  isPromocode.value = true;
  nextTick(() => {
    document.getElementById('promoInput')?.focus();
  });
};

const search = async () => {
  if (typeof window !== 'undefined' && (window as any).ym) {
    (window as any).ym(45380325, 'reachGoal', 'button_findcar');
  }
  cars.loading = true;
  locationsStore.loading = true;

  const date_1 = nuxtApp.$moment(dateStart.value).format('DD.MM.YYYY');
  const date_2 = nuxtApp.$moment(dateEnd.value).format('DD.MM.YYYY');
  const time_1 = fromTime.value || '12:00';
  const time_2 = toTime.value || '12:00';
  const promo = promocode.value;

  let fromId = active.value?.id;
  let slug = active.value?.slug;
  let toId = activeOff.value?.id || fromId;

  if (route.params.slug && !route.query['from-id']) {
    slug = slug || (route.params.slug as string);
    await locationsStore.getItem(slug);
		const newPath = route.path.replace(route.params.slug, slug)
		window.history.replaceState(null, '', newPath)
    fromId = Number(locationsStore.item?.searchParams['from-id']);
    toId = activeOff.value?.id || fromId;
  } else {
    await locationsStore.getItem(slug);
    const currentUrl = new URL(window.location.href);
    const newPath = `/${slug}/`;
    currentUrl.pathname = newPath;
    if (fromId) {
      currentUrl.searchParams.set('from-id', fromId.toString());
    }
    if (toId) {
      currentUrl.searchParams.set('to-id', toId.toString());
    }
    window.history.replaceState(null, '', currentUrl.toString());
  }

  if (fromId && toId) {
    cars.payload = {
      'from-id': fromId,
      'to-id': toId,
      'from-date': date_1,
      'to-date': date_2,
      'from-time': time_1,
      'to-time': time_2,
      'promocode': promo,
      'per_page': 24,
      'page': 1,
    };
	cars.activeClassId = 0;
	cars.list = [];

    if (route.params.slug && !route.query['from-id']) {
      locationsStore.loading = false;
      await cars.get();
      cars.loading = false;
    } else if (active.value?.id && active.value?.slug) {
      if (route.name === 'city') {
        locationsStore.loading = false;
        await cars.get();
        setTimeout(() => {
          cars.loading = false;
        }, 1000);
      } else {
        router.push(`/${slug}?from-id=${fromId}&to-id=${toId}&from-date=${date_1}&to-date=${date_2}&from-time=${time_1}&to-time=${time_2}&promocode=${promo}`);
      }
    }
  }
};

setDefaultParams();

const daysCounter = computed(() => {
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const startTimeInMinutes = timeToMinutes(fromTime.value);
  const endTimeInMinutes = timeToMinutes(toTime.value);
  const timeDifferenceInHours = (endTimeInMinutes - startTimeInMinutes) / 60;

  const dateDifferenceInDays = nuxtApp.$moment(dateEnd.value).diff(nuxtApp.$moment(dateStart.value), 'days');

  if (dateDifferenceInDays === 0) {
    return 1;
  } else if (timeDifferenceInHours < 3) {
    return dateDifferenceInDays;
  } else {
    return dateDifferenceInDays + 1;
  }
});

onMounted(() => {
  const minDate = new Date();
  const dateStartSelect = new Date();
  dateStartSelect.setDate(dateStartSelect.getDate() + 3);

  if (calendar.value === null && props.type === 'desktop') {
    calendar.value = new nuxtApp.$datepicker('#calendar', {
      minDate: minDate,
      startDate: minDate,
      range: true,
      selectedDates: [dateStartSelect],
      onSelect(data: any) {
        if (data.date.length === 2) {
          isShowCalendar.value = false;
          dateStart.value = data.date[0];
          dateEnd.value = data.date[1];
        }
      },
    });
  }

  if (calendarMob.value === null && props.type === 'mobile') {
    calendarMob.value = new nuxtApp.$datepicker('#calendar_mob', {
      minDate: minDate,
      range: true,
      onSelect(data: any) {
        if (data.date.length === 2) {
          isShowCalendarMobile.value = false;
          dateStart.value = data.date[0];
          dateEnd.value = data.date[1];
        }
      },
    });
  }

  document.addEventListener('click', (event) => {
    const target = event.target as HTMLElement;

    if (!target.classList.contains('order-form__autocomolete-item--pick-up') && !target.classList.contains('order-form__pick-up')) {
      isFocusedPickUp.value = false;
    }

    if (!target.classList.contains('order-form__autocomolete-item--drop-off') && !target.classList.contains('order-form__drop-off')) {
      isFocusedDropOff.value = false;
    }

    if (!target.classList.contains('order-form__period') && !target.closest('.calendar')) {
      isShowCalendar.value = false;
    }

    if (!target.classList.contains('order-form__autocomolete--time') && !target.classList.contains('order-form__control')) {
      timepicker1.value = false;
      timepicker2.value = false;
    }
  });
});
watch(
  () => locationsStore.item,
  async(newData, oldData) => {
    document.title = newData.seo_title;
    let id = newData.searchParams['from-id'];

    if (id && locationsStore.pickUpList.find(l => l.id === id)) {
      active.value = setActivePickupPoint(id);
      await locationsStore.getDropOffList(active.value.id);
      activeOff.value = setActiveDropOffPoint(id);
    }
  },
  { deep: true }
);
</script>

<template>
	<div class="order-form__top">
		<nuxt-link to="/" class="order-form__logo"></nuxt-link>
		<div class="order-form__tabs">
			<nuxt-link to="/" class="order-form__tabs-item" :class="{active: route.name !== 'corporate'}" v-if="route.name == 'corporate'">
				<p>Физлица</p>
			</nuxt-link>
			<div class="order-form__tabs-item" :class="{active: route.name !== 'corporate'}" v-else>
				<p>Физлица</p>
			</div>
			<nuxt-link to="/corporate" class="order-form__tabs-item" :class="{active: route.name == 'corporate'}">
				<p>Юрлица</p>
			</nuxt-link>
		</div>
		<div class="order-form__fields">
			<div class="order-form__box">
				<div class="order-form__field with-line pin">
					<label class="form-label">Получение</label>
					<input type="text" :placeholder="active?.name ? active.name : 'Выберите город'" class="order-form__control order-form__pick-up" @focus="isFocusedPickUp = true" v-model="pickUp">
				</div>
				<div v-if="isFocusedPickUp" class="order-form__autocomolete">
					<div v-for="point in locationsStore.pickUpList.filter(p => p.name.toLowerCase().includes(pickUp.toLowerCase()))" @click="setPoint(point)" class="order-form__autocomolete-item order-form__autocomolete-item--pick-up">
						<font-awesome-icon :icon="['fas', 'building']" v-if="point.type == 'city'" />
						<font-awesome-icon :icon="['fas', 'subway']" v-if="point.type == 'railway_station'" />
						<font-awesome-icon :icon="['fas', 'plane']" v-if="point.type == 'airport'" />
						<font-awesome-icon :icon="['fas', 'location-dot']" v-if="point.type == null" />
						{{point.name}}
					</div>
				</div>
				<div class="order-form__field pin" v-if="activeOff">
					<label class="form-label">Возврат</label>
					<input type="text" :placeholder="activeOff.name" class="order-form__control order-form__drop-off" @focus="isFocusedDropOff = true" v-model="dropOff">
				</div>
				<div v-if="isFocusedDropOff && locationsStore.dropOffList && locationsStore.dropOffList.length > 0" class="order-form__autocomolete">
					<div v-for="point in locationsStore.dropOffList.filter(p => p.name.toLowerCase().includes(dropOff.toLowerCase()))" @click="setPointDropOff(point)" class="order-form__autocomolete-item order-form__autocomolete-item--drop-off">
						<font-awesome-icon :icon="['fas', 'building']" v-if="point.type == 'city'" />
						<font-awesome-icon :icon="['fas', 'subway']" v-if="point.type == 'railway_station'" />
						<font-awesome-icon :icon="['fas', 'plane']" v-if="point.type == 'airport'" />
						<font-awesome-icon :icon="['fas', 'location-dot']" v-if="point.type == null" />
						{{point.name}}
					</div>
				</div>
				
			</div>
			<div class="order-form__box">
				<div class="order-form__field">
					<label class="form-label">Даты аренды</label>
					<div class="order-form__period" @click="toggleDatepicker" v-if="props.type == 'desktop'">
						{{$moment(dateStart).format('DD.MM.YYYY')}} - {{$moment(dateEnd).format('DD.MM.YYYY')}}
						<div class="order-form__period-amount">{{daysCounter}} {{ $getNumberLabels(daysCounter, ['день', 'дня', 'дней']) }}</div>
					</div>
					<div class="order-form__period" @click="toggleDatepickerMobile" v-if="props.type == 'mobile'">
						{{$moment(dateStart).format('DD.MM.YYYY')}} - {{$moment(dateEnd).format('DD.MM.YYYY')}}
						<div class="order-form__period-amount">{{daysCounter}} {{ $getNumberLabels(daysCounter, ['день', 'дня', 'дней']) }}</div>
					</div>
				</div>
				<template v-if="props.type == 'desktop'">
					<Transition name="slide-fade">
						<div id="calendar" v-show="isShowCalendar" class="calendar"></div>
					</Transition>
				</template>
				<template v-if="props.type == 'mobile'">
					<Transition name="slide-fade">
						<div id="calendar_mob" v-show="isShowCalendarMobile" class="calendar"></div>
					</Transition>
				</template>
			</div>
			<div class="order-form__box order-form__box--date">
				<div class="order-form__field order-form__field--time">
					<label class="form-label">Время подачи</label>
					<input type="text" readonly v-model="fromTime" class="order-form__control" @click="showTime(1)">
					<div class="order-form__autocomolete order-form__autocomolete--time" v-if="timepicker1">
						<div v-for="time in TIME_DATA" :key="time.time" @click="setFromTime(time.time)" class="order-form__autocomolete-item">{{time.time}}</div>
					</div>
				</div>
				<div class="order-form__field order-form__field--time">
					<label class="form-label">Время возврата</label>
					<input type="text" readonly v-model="toTime" class="order-form__control" @click="showTime(2)">
					<div class="order-form__autocomolete order-form__autocomolete--time" v-if="timepicker2">
						<div v-for="time in TIME_DATA" :key="time.time" @click="setToTime(time.time)" class="order-form__autocomolete-item">{{time.time}}</div>
					</div>
				</div>
			</div>
			<div class="order-form__row column">
				<button class="promocode-btn" v-if="!isPromocode" @click="toggleIsPromocode">
					у меня есть Промокод
				</button>
        <div class="promocode-input" v-else>
          <input type="text" placeholder="введите промокод" class="promocode-control" id="promoInput" v-model="promocode">
          <template v-if="cars.payload.promocode">
            <div v-if="cars.loading" class="round-loader"></div>
            <template v-else>
              <div v-if="cars.isPromocodeAccepted" class="promocode-icon promocode-checked"></div>
              <div v-else class="promocode-icon promocode-error"></div>
            </template>
          </template>
        </div>
				<button class="button button-accent button-search" @click="search">Поиск</button>
			</div>
		</div>
		<div class="order-form__banner">
			<div class="order-form__banner-image">
			<img src="@/assets/img/present.png" class="order-form__banner-img" />
			</div>
			<p class="order-form__banner-text">Получите скидку на первую аренду с промокодом <span class="order-form__banner-text--bold">WINTER</span></p>
		</div>
	</div>
</template>

<style>
	.order-form__logo {
		display: block;
	}
	.order-form__pick-up {
		cursor: pointer;
	}
	.order-form__drop-off {
		cursor: pointer;
	}
	.order-form__autocomolete {
		background-color: #F9F9F9;
		color: #000;
		position: absolute;
		width: calc(100% + 2px);
		margin-left: -1px;
		margin-top: -1px;
		font-size: 14px;
		height: 205px;
		overflow-y: auto;
		z-index: 100;
		border-radius: 0 0 8px 8px;
		border: 1px solid rgba(0, 0, 0, 0.05);
	}
	.order-form__autocomolete-item {
		padding: 10px 15px;
		cursor: pointer;
		font-size: 13px;
		line-height: 1.5;
		&:hover {
			background-color: rgba(0,0,0,0.05);
		}
	}
	.order-form__box:after {
		z-index: 100;
	}

	.order-form__autocomolete::-webkit-scrollbar {
		background-color: rgba(255, 109, 16, 0.1);
		width: 5px;
	}

	.order-form__autocomolete::-webkit-scrollbar-thumb {
		background-color: #FF6D10;
	}
	.slide-fade-enter-active {
		transition: all 0.3s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.3s ease-in;
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(20px);
		opacity: 0;
	}

	.promocode-control {
		display: block;
    margin-bottom: 0 !important;
    width: 100% !important;
	}

	.promocode-input {
		position: relative;
    margin-bottom: 7px;
	}

  @media screen and (max-width: 1020px) {
    .promocode-input {
      margin-bottom: 0;
      width: 49%;
    }
  }

  @media screen and (max-width: 760px) {
    .promocode-input {
      width: 100%;
    }
  }

  .promocode-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .promocode-checked {
    background-image: url(@/assets/img/check-solid.svg);
  }

  .promocode-error {
    background-image: url(@/assets/img/xmark-solid.svg);
  }

  .round-loader {
    width: 20px;
    height: 20px;
    border: 3px solid #ccc;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
